﻿
@media (min-width: 975px) {
    .navbar {
        height: 120px;

        img {
            height: 80px;
            transform: translateY(-20px);

            @media (min-width: 1200px) {
                transform: translate(-20px, -20px);
            }
        }

        .navbar-nav .nav-item {
            padding: 0 20px;

            a {
                &.nav-link {
                    padding: 0;
                    text-transform: capitalize;
                }
            }
        }

        .navbar-collapse {
            align-self: stretch;

            .navbar-nav {
                align-items: flex-end;
                height: 100%;
            }
        }
    }

    .navbar .navbar-nav .nav-item a.nav-link {
        text-align: right;
    }
}
