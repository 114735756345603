﻿.footer-links {
    color: white;
    text-decoration: none;
    font-weight: 400;

    &:hover {
        color: white;
    }
}

.copyright {
    height: 100%;
    display: flex;
    font-size: 11px;
    justify-content: center;

    @media (min-width: 768px) {
        justify-content: flex-start;
        align-items: flex-end;
    }
}

.awards {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 40px;

    img {

        &:nth-child(1) {
            width: 70px;
        }

        &:nth-child(2) {
            height: 68px;
        }

        &:nth-child(3) {
            width: 75px;
        }

        &:nth-child(4) {
            width: 75px;
        }
    }
}

.social-media-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 15px;
    margin: -5px;

    img {
        width: 26px;
        height: 26px;
        margin: 5px;
    }

    @media (min-width: 768px) {
        justify-content: flex-start;
    }
}

.pbFooter {
    padding-top: 20px;

    h5 {
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 1px;
    }

    a, p {
        font-size: 12px;
        font-weight: 200;
    }

    a {
        line-height: 25px;
    }

    .nowrap {
        flex-wrap: wrap;

        @media (min-width: 992px) {
            flex-wrap: nowrap;
        }
    }

    .content-columns {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @media (min-width: 992px) {
            max-width: 750px;
            flex-wrap: nowrap;
        }

        .content-col {
            width: 100%;

            @media (min-width: 768px) {
                width: calc(100% / 4);
            }

            @media (min-width: 992px) {
                width: calc(100% / 3);
            }
        }
    }

    .content-col {
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;

        &:nth-child(3) {
            padding-right: 15px;
        }

        @media (min-width: 992px) {
            max-width: 180px;
            padding: 0 30px;
        }

        .contactInfo {
            margin-top: 0;
            line-height: 25px;
        }

        &.last {
            width: 100%;
            padding: 0 15px;
            max-width: unset;

            @media (min-width: 992px) {
                min-width: 400px;
                padding: 0 30px;
                max-width: calc(100% - 750px);

                .row {
                    margin-right: -30px;
                }
            }
        }
    }

    .page-links {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 30px 20px 15px;

        @media (min-width: 768px) {
            width: calc(100% / 4);
        }

        @media (min-width: 992px) {
            max-width: 200px;
        }
    }
}

.asc {
    margin-top: 20px;
    text-align: center;

    @media (min-width: 768px) {
        margin-top: 0;
        text-align: right;
    }

    img {
        width: 126px;
    }
}

.pbFooterContent {
    font-size: 11px;
    line-height: 17px;
    font-weight: 200;
    text-align: center;

    @media (min-width: 768px) {
        text-align: right;
    }

    @media (min-width: 768px) {
        padding-left: 40px;
    }
}

.extrra {
    flex-direction: column-reverse;

    @media (min-width: 768px) {
        flex-direction: row;
    }
}

.pbBlog {
    padding: 40px 0;

    @media (min-width: 768px) {
        padding: 40px 0 60px 0;
    }

    .text-center {
        padding-top: 20px;
    }
}

.info-title {
    font-size: 1.1rem;
}