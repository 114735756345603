.products {

    @media (min-width: 768px) {
        padding-bottom: 40px;
    }

    h3 {
        color: $brand-accent;
    }
}

h1, h2, h3 {
    &.branded {
        color: $brand-accent;
    }
}

.product-table {
    padding-bottom: 0;

    .table-header {
        display: flex;

        p {
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: 0;
            color: white;
            font-weight: bold;
            margin-right: 10px;

            &:first-child {
                margin-left: 10px;
            }
        }

        p:first-child {
            flex-grow: 3;
            flex-shrink: 0;
            flex-basis: 0;
        }
    }

    form {
        margin-bottom: 0;
    }

    .btn {
        margin-top: 20px;
    }

    .table-row {
        background-color: $brand-accent;
        border-radius: 5px;
        padding-left: 5px;
        margin-bottom: 3px;
        display: flex;

        p {
            color: white;
            font-size: 0.8rem;
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: 0;
            margin-top: 3px;
            margin-bottom: 3px;
            margin-left: 5px;
            text-align: center;
        }

        :first-child {
            text-align: left;
        }

        &.prawns p {
            text-align: left;
            font-weight: 500;
        }

        &.large-row {
            min-height: 40px;

            p:first-child {
                flex-grow: 1;
                text-align: left;
            }

            p {
                font-size: 16px;
                padding: 6px 0;
                text-align: center;
            }
        }

        &.header {
            background-color: white;

            p {
                color: $black-color !important;
                font-weight: 500;
            }
        }
    }

    .form-group {
        margin-bottom: 0;

        &:last-of-type {
            margin-bottom: 1rem;
        }
    }

    &.size-contianer {
        padding-top: 2rem;

        @media (min-width: 768px) {
            padding-top: 0;
        }
    }
}

.crop-list {
    border: none;
    padding-right: 0;

    .crop-list-inner {

        li {
            p {
                color: $brand-accent;
            }

            a {
                font-weight: 400;
                color: $brand-primary;
            }
        }
    }
}

.container.index-container {
    padding: 20px 15px;

    h3 {
        padding-bottom: 20px;
    }

    .index {
        display: flex;

        .index-panel {
            display: none;

            @media (min-width: 768px) {
                display: block;
            }
        }

        ul {
            list-style: none;
            padding-left: 0;
            width: 200px;
            align-self: flex-start;
        }

        .index-item {
            font-weight: 300;
            font-size: 14px;
            padding: 7px 40px 7px 0;

            &.selected {
                font-weight: 600;
                font-size: 16px;
                color: $brand-accent
            }

            &:hover {
                cursor: pointer;
            }
        }

        .index-body {
            padding-top: 15px;
            margin-top: -55px;

            @media (min-width: 768px) {
                padding-left: 20px;
            }

            h2 {
                color: $brand-accent;
                font-size: 24px;
            }
        }
    }
}

.product-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}



.product-actions {
    display: flex;
    flex-direction: column;
    margin: 0 -0.5rem;
    padding-top: 20px;

    a {
        flex: 100%;
        margin: 0 0.5rem;

        &:first-child {
            margin-bottom: 5px;

            @media (min-width: 576px) {
                margin-bottom: 0;
            }
        }
    }

    @media (min-width: 576px) {
        flex-direction: row;
    }

    @media (min-width: 1200px) {
        padding-top: 0px;
    }
}

.carousel-indicators {
    &.carousel-panel {
        padding-left: 15px !important;

        li {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: none;

            &.active {
                background-color: $brand-accent;
                transform: scale(1);
            }
        }
    }
}

.carousel-image {
    background-position: center center;
    background-size: cover;
    margin: 0;
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;

    h1, h3 {
        color: white;
    }

    a {
        border-radius: 2px;
    }
}

.carousel.product {
    .brand {
        padding-top: 30px;
    }

    .logo {
        margin-bottom: 30px;
        width: 150px;
        position: relative;
    }

    .carousel-image {
        padding: 20px 0 200px 0;

        @media (min-width: 768px) {
            padding: 20px 0 150px 0;
        }
    }

    .carousel-rte {
        padding-top: 6px;
        color: white;

        h2, h3 {
            margin-top: 0;
        }
    }

    .actions-container {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        padding-bottom: 40px;

        a {
            margin: 0 0.5rem;
            color: white;
        }

        .carousel-indicators {
            order: 3;

            @media (min-width: 768px) {
                display: block;
                order: unset;
            }

            &.carousel-panel {
                position: relative;
                margin: 0;
                padding: 0;
                bottom: 0;

                li {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    border: none;

                    &.active {
                        background-color: $brand-accent;
                        transform: scale(1);
                    }
                }
            }
        }

        .actions {
            display: flex;
            flex-direction: column;
            align-items: center;

            a {
                width: 100%;

                &:first-child {
                    margin-bottom: 5px;

                    @media (min-width: 768px) {
                        margin-bottom: 0;
                    }
                }

                @media (min-width: 768px) {
                    width: auto;
                }
            }

            @media (min-width: 768px) {
                flex-direction: row;
            }

            .button-right {
                flex-grow: 1;
                display: flex;
                justify-content: flex-end;
                width: 100%;

                a {
                    margin: 0;

                    @media (min-width: 768px) {
                        margin: 0 5px;
                    }
                }

                @media (min-width: 768px) {
                    width: auto;
                }
            }
        }
    }
}

.rte {
    h3 {
        color: $brand-accent;
    }

    a {
        color: $brand-accent;
    }
}

.section-image {
    margin: 40px 0;

    .section-description {
        padding-bottom: 40px;

        h2.title {
            font-size: 30px;
        }
    }
}

.main {
    :last-child {
        &.section-image {
            margin-bottom: 0;
        }
    }
}

.crop-list {

    .crop-list-inner {
        border: none;
        width: 200px;

        li {
            font-weight: 500;
            padding: 7px 40px 7px 0;

            a {
                color: $brand-primary;
            }
        }
    }
}

.right-content {

    .container {
        padding: 0;

        @media (min-width: 768px) {
            padding: 0 15px;
        }
    }

    .row {
        &:after {
            border-left: 1px solid #ce7047;
            padding-left: 30px;
            height: calc(100% - 20px);
            position: absolute;

            @media (min-width: 992px) {
                content: "";
            }
        }

        > :first-child {
            @media (min-width: 992px) {
                padding-left: 30px;
            }
        }
    }
}

.card-category {
    margin-bottom: 0;

    span, .text-success {
        color: #333 !important;
        font-size: 13px;
    }
}

.card-title {
    color: $brand-accent;
    margin: 10px 0;
}

.card.card-blog .card-description {
    white-space: normal;
}

.card-description {
    color: #333;
}

.card-body {
    > a h6, > h6 {
        color: $brand-accent !important;
        font-size: 16px;
        font-weight: 400;
    }
}

.info .info-title {
    margin-bottom: 7px;
}

.background-image-panel {
    margin-top: 40px;
    padding-bottom: 80px;
}

.background-image-feature-panel {
    margin-top: 40px;
}

.section {
    padding: 0;
    position: relative;

    @media (min-width: 768px) {
        padding: 10px 0;
    }

    @media (min-width: 992px) {
        padding: 10px 0;
    }
}

strong {
    font-weight: 500;
}

.title {
    &.accent {
        color: $brand-accent !important;
    }
}

.background-image-panel {
    margin-top: 40px;
    padding-bottom: 80px;
}

.main .container {
    max-width: 1140px !important;
}


.btn {
    &.full-width {
        width: 100%;
        white-space: break-spaces;
    }
}

.button-list {
    padding: 2rem 0;
    width: 100%;
}

.pbBlog .card.card-blog .img.img-raised {
    height: unset;
}