﻿.accordion-toggle-pj {
    background-color: #d2ddbf;
    padding: 15px 0px 15px 15px;
    display: block;
    color: #232b34;

    &:hover, &:focus, &:active {
        color: #232b34;
    }


    &.collapsed::before {
        content: " + ";
    }
}

.accordion-toggle-pj::before {
    content: " - ";
}

.accordion-content-pj {
    background-color: #e6e8e2;
}

.accordion-element-pj {
    border-radius: 5px;
    margin-bottom: 2px;

    > p {
        padding: 15px 0px 15px 15px;
        margin-bottom: 0;
    }
}

.accordion-container {
    padding-bottom: 30px;
}