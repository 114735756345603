// Bootstrap brand color customization

/*     brand Colors              */

$brand-primary: #00B5E2 !default;
$brand-info: #00303C !default;
$brand-success:              $green-500 !default;
$brand-warning:              $orange-500 !default;
$brand-danger:               $red-500 !default;
$brand-rose:                 $pink-500 !default;
$brand-inverse: #00303C !default;

