﻿.pbFooter {
    margin-top: 40px;
    padding-top: 40px;
    background-color: $brand-inverse;
    color: white;
    text-align: center;
    font-size: 12px;
    z-index: 1;
    position: relative;

    &.no-margin {
        margin-top: 0;
    }

    @include media-breakpoint-up(md) {
        text-align: left;
    }

    h5 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        margin: 0 0 5px;
    }

    a {
        display: inline-block;
    }

    nav {
        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }

    .contactInfo {
        margin-top: 8px;
        font-size: 12px;

        p {
            font-size: 12px;
        }

        a {
            color: white;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .socialLink {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        color: white;
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }

        &:hover {
            color: darken($link-color, 5%);

            img {
                opacity: 0.8;
            }
        }

        img {
            display: block;
            margin-top: 15px;
        }
    }

    &Content {
        padding: 20px 0 10px;
    }

    .copyright {
        font-size: 11px;
        color: $gray-color;
    }
}

.footer-header-link > a > h5 {
    color: white;
}