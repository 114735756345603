@mixin radio-color($color, $opacity){
  & ~ .check,
  & ~ .circle  {
    opacity: $opacity;
  }

  & ~ .check {
    background-color: $color;
  }

  & ~ .circle  {
    border-color: $color;
  }
}

.form-check{
    .form-check-label {
        cursor: pointer;
        padding-left: 25px;
        position: relative;
        @include mdb-label-color-toggle-focus();

    span {
        display: block;
        position: absolute;
        left: -1px;
        top: -1px;
        transition-duration: 0.2s;
    }
    .circle {
        border: 1px solid $mdb-radio-color-off;
        height: 15px;
        width: 15px;
        border-radius: 100%;
        top: 1px;

        .check {
            height: 15px;
            width: 15px;
            border-radius: 100%;
            background-color: $mdb-radio-color-on;
            @include transform-scale3d(unquote('0,0,0'));


        }
    }

  }

    .form-check-input {
        opacity: 0;
        height: 0;
        width: 0;
        overflow: hidden;


        &:checked {
            @include radio-color($mdb-radio-color-on, 1);

            .check:before {
                animation: checkboxOn .5s forwards;
            }
        }
        &:checked ~ .circle .check {
            @include transform-scale3d(unquote('0.65, 0.65, 1'));
        }

    }

    .form-check-input[disabled] {

        // light theme spec: Disabled: #000000, Opacity  26%
        @include radio-color($black, 0.26);

        & + .circle .check{
            background-color: $black;
        }
    }


    .form-check-sign{
        vertical-align: middle;
        position: relative;
        top: -2px;
        float: left;
        padding-right: 10px;
        display: inline-block;

    }

}

.form-check {
  .form-check-label {
    .circle {
      &:before {
        display: block;
        position: absolute;
        left: -1px;
        content: "";
        background-color: rgba(0, 0, 0, 0.84);
        height: 15px;
        width: 15px;
        border-radius: 100%;
        z-index: 1;
        opacity: 0;
        margin: 0;
        top: -1px;
        -webkit-transform: scale3d(2.3,2.3,1);
        -moz-transform: scale3d(2.3,2.3,1);
        -o-transform: scale3d(2.3,2.3,1);
        -ms-transform: scale3d(2.3,2.3,1);
        transform: scale3d(2.3,2.3,1);
      }
    }

    .form-check-input:checked + .circle:before {
      animation: rippleOn .5s;
    }

    .form-check-input:checked + .circle .check:before {
      color: #FFFFFF;
      box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
      animation: checkboxOn 0.3s forwards;
    }
  }
}

.form-check + .form-check {
  margin-top: 0;
}

@keyframes checkboxOn {
  0% {
    box-shadow:
      0 0 0 10px,
      10px -10px 0 10px,
      32px 0 0 20px,
      0px 32px 0 20px,
      -5px 5px 0 10px,
      15px 2px 0 11px;
  }
  50% {
    box-shadow:
      0 0 0 10px,
      10px -10px 0 10px,
      32px 0 0 20px,
      0px 32px 0 20px,
      -5px 5px 0 10px,
      20px 2px 0 11px;
  }
  100% {
    box-shadow:
      0 0 0 10px,
      10px -10px 0 10px,
      32px 0 0 20px,
      0px 32px 0 20px,
      -5px 5px 0 10px,
      20px -12px 0 11px;
  }
}


@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
