﻿.right-content {
    padding-left: 0;

    @media (min-width: 768px) {
        padding-left: 20px;
    }
}

div.crop-dropdown {
    display: block;

    @media (min-width: 768px) {
        display: none;
    }
}

.crop-list {
    margin-top: 4rem;
    border-right: 1px #acc682 solid;
    display: none;

    &-inner {
        list-style: none;
        padding-left: 0;

        > li {
            > * {
                font-size: 14px;
            }

            > a {
                color: #044c29;
            }

            > p {
                margin-bottom: 0;
                color: #044c29;
                font-weight: bold;
            }
        }
    }

    @media (min-width: 768px) {
        display: block;
    }
}

.crop-title {
    margin: 0;
    padding-top: 30px;
}

.crop-info-toggle-inner {
    padding: 15px;

    &> i {
        float: right;
    }
}

.crop-info-toggle-outer {
    background-color: $brand-primary;
    border-bottom: solid 1px #aaa;
    color: white;
    font-weight: normal;

    > a.collapsed > div > i.up {
        display: none;
    }

    > a > div > i.down {
        display: none;
    }

    > a.collapsed > div > i.down {
        display: inline-block;
    }
}

.accordion-crops {
    padding-top: 10px;
}

.collapse-outer {
    border-bottom: 1px solid #aaa;
}