﻿.pbBlog {


  .nav.nav-pills {
    justify-content: center;
  }

  &Tags {
    padding: 30px 0 10px;
  }


  .card.card-blog {
    min-height: calc(100% - 90px);

    .card-body {
      display: flex;
      flex-direction: column;
    }

    .img.img-raised {
      max-height: 17rem;
      height: 17rem;
      object-fit: cover;
      object-position: top center;
    }

    .card-description {
      flex: 1 0 auto;
    }
  }
}


.pgArticleTags {
  h6 {
    display: inline;
  }
}