﻿.pbHeaderLogo {
  margin-top: -6px;

  &.transparent {
    display: none;
  }
}

.navbar-transparent {
  .pbHeaderLogo {
    display: none;

    &.transparent {
      display: initial;
    }
  }
}

// Material Kit Overrides
.navbar .navbar-nav .nav-item .nav-link {
  background: transparent;
  border: none;
  cursor: pointer;
}

.page-header {
  min-height: 720px;

  @include media-breakpoint-up(sm) {
    min-height: initial;
  }

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1;
    padding-bottom: 8px;

    @include media-breakpoint-up(md) {
      font-size: 3.5rem;
    }
  }

  h3 {
  }
}

