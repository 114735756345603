﻿@import 'material-kit';

@import 'components/_header';
@import 'components/_footer';

@import 'components/_twoColumnPanel';
@import 'components/_staffProfile';
@import 'components/_featurePanel';
@import 'components/_umbracoForm.scss';
@import 'components/_featureCarousel.scss';
@import 'components/_cropsPage.scss';
@import 'components/_nutrientTable.scss';
@import 'components/_accordianPanel.scss';

@import 'pages/_blog';

.modal-open {
  .main {
    position: initial;
  }
}

.pbContactForm {
  margin-top: 40px;
}

.description.rte {
  img {
    max-width: 100%;
    height: initial !important;
  }
}

.pbCarouselPanel {
    overflow: hidden;
}

// Material Kit Overrides

.header-filter::before {
  background: rgba(0, 0, 0, 0.35);
}

.section:not(.section-image,.section-dark) + .section:not(.section-image.section-dark) {
  padding-top: 0;
}

.section {
  padding: 20px 0;
}

.info {
  padding: 20px 0;
}

.description, .info p {
  color: $black-color;
}

p {
  font-size: 16px;
}


/*Mail chimp styles*/
#mce-error-response {
  a {
    display: none;
  }
}

.page-header.page-header-small {
    //min-height: initial;
    max-height: 65vh;
    padding-top: 15vh;
    align-items: flex-start;
}
.main.main-raised.main-small {
  margin-top: -10vh;
}

.article-top {
    padding-top: 60px;
}