.brand.reef {
    text-transform: uppercase;
    padding-bottom: 20%;

    h1 {
        font-size: 40px;
        margin-bottom: 0;
        font-weight: 600;
    }

    h3 {
        font-size: 32px;
        font-weight: 600;
        line-height: 42px;
        margin-top: 10px;

        @media (min-width: 576px) {
            font-size: 48px;
            line-height: 52px;
        }

        @media (min-width: 768px) {
            font-size: 68px;
            line-height: 70px;
        }
    }

    hr {
        width: 80%;
        margin-left: 0;
        border-bottom: 1px solid $brand-accent;
        margin: 0;
    }
}

.pacific-reef {
    .active {
        z-index: 2;

        .page-header {
            opacity: 1;
            z-index: 2;
        }
    }

    .page-header {
        transition: 0.5s ease-in-out;
        opacity: 0;
    }

    .carousel-inner {
        height: 100vh;
    }

    .carousel-item {
        display: block;
        position: absolute;
    }
}

.page-header {

    &.page-header-small {
        padding-top: 0;
        max-height: 75vh;

        .brand {
            margin-top: calc(75vh * 0.45);
        }
    }

    h1.title {
        font-family: "Montserrat", "Helvetica", "Arial", sans-serif !important;
        font-size: 46px;
        margin-bottom: 10px;
    }

    h3 {
        margin-top: 0;
        font-size: 16px;
        font-weight: 500;
        max-width: 500px;
    }

    &:after, &:before {
        content: none;
    }
}
