﻿.pbTwoColumnPanel {

    .image-container {
        margin-bottom: 30px;

        &:first-child {
            margin-top: 48px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }


    img.img-raised {
        max-width: 100%;
        width: 100%;
        border-radius: 15px;
    }

    img.img-flat {
        max-width: 100%;
        width: 100%;
        border-radius: 15px;
    }

    .description {

        &:first-child {
            margin-top: 48px;
        }
    }
}
