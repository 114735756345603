﻿.table-container {
    display: flex;
    flex-wrap: wrap;

    .nutrient-table {
        margin-bottom: 30px;

        .table-header {
            display: flex;

            p {
                flex-grow: 1;
                flex-shrink: 0;
                flex-basis: 0;
                color: white;
                font-weight: bold;
                margin-right: 10px;

                &:first-child {
                    margin-left: 10px;
                }
            }

            p:first-child {
                flex-grow: 3;
                flex-shrink: 0;
                flex-basis: 0;
            }
        }

        .table-row {
            background-color: white;
            border-radius: 5px;
            padding-left: 5px;
            margin-bottom: 5px;
            display: flex;

            p {
                font-size: 0.8rem;
                flex-grow: 1;
                flex-shrink: 0;
                flex-basis: 0;
                margin-top: 3px;
                margin-bottom: 3px;
                margin-left: 5px;
            }

            p:first-child {
                flex-grow: 3;
                flex-shrink: 0;
                flex-basis: 0;
            }
        }

        .green-row {
            background-color: #81c258;
        }
    }
}
