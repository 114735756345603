@import './overrides/pacific-reef/variables';
@import 'styles';
@import './overrides/pacific-reef/hero';
@import './overrides/pacific-reef/panels.scss';
@import './overrides/pacific-reef/footer.scss';
@import './overrides/pacific-reef/nav.scss';

.main {
    position: relative;
}

.container {
    max-width: 1200px !important;
}

.card-body .pj-text-primary {
    font-size: 0.8rem;
}

h2.title, .crop-title {
    color: #333;
}

.pbFooter {
    background-color: #333333;
}

h2 {
    font-size: $h2-size;
}

body, p {
    font-family: 'Montserrat-Regular', 'Montserrat', sans-serif;
    font-size: $font-size;
}

.btn.btn-primary {
    box-shadow: none;
    border: 1px solid $brand-primary;
}

.btn.btn-primary:hover {
    background-color: white;
    color: $brand-primary !important;
    box-shadow: none;
}

.badge.badge-success {
    background-color: $brand-accent;
}