﻿.pj- {
    &text-primary {
        color: #044C29;
    }
}

.card-outer {
    padding-top: 2rem;
}

ol.carousel-indicators.feature-indicators {
    bottom: -2.5rem;
}

.feature-panel-container {
    padding-bottom: 1.5rem;
}

.pb-card-carousel-panel {
    height: 23rem;
}

.pi-card-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    :after {
        content: "...";
    }
}

.carousel-wrapper {
    margin: 0;
    padding: 0;

    @media (min-width: 576px) {
        overflow: hidden;
        padding: 0 50px;
        margin: 0 -50px;
    }
}

.carousel-card-container {
    padding: 0 10px;
}

.feature-carousel-control-prev,
.feature-carousel-control-next {
    color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    // Use flex for alignment (1-3)
    display: flex; // 1. allow flex styles
    align-items: center; // 2. vertically center contents
    justify-content: center; // 3. horizontally center contents
    text-align: center;

    @include hover-focus {
        text-decoration: none;
        outline: 0;
        opacity: .9;
    }

    > button {
        background: none;
        border: none;

        > i {
            color: white;
            font-size: 40px;
        }
    }
}

.feature-carousel-control-prev {
    left: -50px;
}

.feature-carousel-control-next {
    right: -50px;
}

ul.slick-dots li button:before {
    color: white;
    font-size: 15px;
}

ul.slick-dots li.slick-active button:before {
    opacity: .75;
    color: white;
}

.slick-initialized {
    .feature-carousel-control- {
        &next i, &prev i {
            display: none;

            @media (min-width: 576px) {
                display: flex;
            }
        }
    }
}
