﻿.pbVerticalFeaturePanel.info {
  max-width: none;
  padding: 0;
}


.pbCardFeaturePanel {
    min-height: calc(100% - 90px);
}

.pbFeaturePanel {

    &.section-dark {

        .card.card-blog {
            .card-description {
                color: white;
            }
        }

        .info {
            .info-title, p {
                color: white;
            }
        }
    }
}

.card.card-blog {
  .card-description {
    white-space: pre-line;
  }
}
.info {
  .info-title, p {
    white-space: pre-line;
  }
}

.info {
  a .info-title {
    &:hover, &:focus, &:active {
      color: $brand-primary;
    }
  }
}

.balance {
    justify-content: center;
}

h2.feature-panel-title {
    margin-top: 0 !important;
    text-align: left;

    @media (max-width: 768px) {
        text-align: center;
    }
}

.feature-panel-container {
    padding-top: 30px;
}